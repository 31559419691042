// src/Components/CarouselIndicators.js

import React from 'react';
import './CarouselIndicators.css';
import CarouselItem from './CarouselItem';

import Ind1 from './L1-3 - Ind1.png';
import Ind2 from './L1-3 - Ind2.png';
import Ind3 from './L1-3 - Ind3.png';
import Ind4 from './L1-3 - Ind4.png';
import Ind5 from './L1-3 - Ind5.png';
import Ind6 from './L1-3 - Ind6.png';

export default function CarouselIndicators() {
  const images = [
    { src: Ind1, alt: 'Indicator 1', label: 'Bullish/Bearish Volume' },
    { src: Ind2, alt: 'Indicator 2', label: 'Volume Profile' },
    { src: Ind3, alt: 'Indicator 3', label: 'Forex Session Tracker' },
    { src: Ind4, alt: 'Indicator 4', label: 'Forex Macro Metrics' },
    { src: Ind5, alt: 'Indicator 5', label: 'EMA Volume' },
    { src: Ind6, alt: 'Indicator 6', label: 'Adaptive Smooth EMA' },
  ];

  // Duplicate the images array to create an infinite loop effect
  const items = [...images, ...images];

  return (
    <div className="carousel-container">
      <div className="carousel-track">
        {items.map((image, index) => (
          <CarouselItem key={index} image={image} />
        ))}
      </div>
    </div>
  );
}