import React, { useState } from "react";
import TrailerVideo from "../Components/MacroGlide Website Intro_11.mp4"; // Trailer video file
import "./SplashScreen.css"; // CSS for the splash screen

const SplashScreen = ({ onVideoEnd }) => {
  const [isVideoLoading, setIsVideoLoading] = useState(true); // Track the loading state of the video

  const handleVideoLoaded = () => {
    setIsVideoLoading(false); // Hide the loader once the video is ready to play
  };

  return (
    <div className="splash-screen">
      {/* Show a loading spinner or background while the video is loading */}
      {isVideoLoading && <div className="loading-spinner">Loading...</div>}

      <video
        autoPlay
        muted
        className="intro-video"
        onEnded={onVideoEnd} // Trigger when the video ends
        onCanPlayThrough={handleVideoLoaded} // Fire when the video is ready to play
      >
        <source src={TrailerVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default SplashScreen;
