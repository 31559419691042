// src/Components/CarouselItem.js

import React from 'react';

export default function CarouselItem({ image }) {
  return (
    <div className="carousel-card">
      <div className="carousel-label">{image.label}</div>
      <img src={image.src} alt={image.alt} />
      <button className="indicators-button carousel-button">See More</button>
    </div>
  );
}