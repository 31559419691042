// src/Pages/Indicator2.js

import React from 'react';
import './Indicator.css';
import IndicatorImage from '../Components/L1-3 - Ind2.png';
import Logo from '../Components/HL - Our App.png'; // Reuse the same logo

const Indicator2 = () => {
  return (
    <div className="indicator-page">
      {/* OPEN-SOURCE SCRIPT Label */}
      <div className="open-source-label">
        OPEN-SOURCE SCRIPT
      </div>

      {/* Indicator Title */}
      <h1 className="indicator-title">Volume Profile</h1>

      {/* Publication Date */}
      <p className="publication-date">June 5, 2024</p>

      {/* Image Container with Clickable Image */}
      <div className="image-container">
        <a
          href="https://ru.tradingview.com/script/6DO6rF4M-Volume-Profile/"
          target="_blank"
          rel="noopener noreferrer"
          className="image-link"
        >
          <img
            src={IndicatorImage}
            alt="Volume Profile Indicator"
            className="indicator-image"
          />
        </a>

        {/* TradingView Account Link */}
        <a
          href="https://ru.tradingview.com/u/MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="tradingview-account-link"
        >
          <img src={Logo} alt="MacroGlide Logo" className="logo-image" />
          <span className="logo-text">MacroGlide</span>
        </a>
      </div>

      {/* Description Section */}
      <div className="indicator-description">
        <h2>Description</h2>
        <p>
          The "Volume Profile" Indicator is a powerful tool for traders, offering a comprehensive analysis of volume distribution over a specified number of bars. This indicator helps identify key levels of support and resistance, based on volume accumulation, and provides insights into market sentiment.
        </p>

        <h2>Features</h2>
        <ul>
          <li>Detailed Volume Analysis: Breaks down volume into up and down movements, total volume, and volume delta, providing a clear view of market activity.</li>
          <li>Customizable Settings: Adjust the number of bars, row size, and value area percentage to tailor the indicator to your trading style.</li>
          <li>Visual Clarity: Display volume profiles on either side of the chart, with color-coded bars for easy interpretation.</li>
          <li>Point of Control (POC) Lines: Highlight the highest volume price levels, with options to show total, bullish, and bearish POC lines.</li>
          <li>Volume Value Labels: Optionally display volume values in real terms or percentages directly on the chart.</li>
        </ul>

        <h2>Flexibility</h2>
        <ul>
          <li>Placement Options: Position the volume profile on the left or right side of the chart for better visual alignment with your analysis.</li>
          <li>Color Customization: Set distinct colors for different volume types, background, and POC lines to match your charting preferences.</li>
          <li>Label Styles: Choose from different label styles and display modes to best fit your needs.</li>
        </ul>

        <h2>Enjoy the game!</h2>
      </div>

      {/* Bottom Link Button */}
      <a
        href="https://ru.tradingview.com/script/6DO6rF4M-Volume-Profile/"
        className="indicator-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Use Indicator in TradingView
      </a>
    </div>
  );
};

export default Indicator2;
