import React, { Component } from "react";
import VideoBackground from "../Components/VideoBackground";
import AppVideo from "../Components/MacroGlide App 1 (Blank).mp4";

export default class Ourapp extends Component {
    render() {
        return (
            <>
                {/* Video background with a custom class for OurApp */}
                <VideoBackground videoSrc={AppVideo} customClass="our-app-background" />
                
                {/* Page content */}
                <div className="page-content">
                    <h1>Our App</h1>
                </div>
            </>
        );
    }
}

