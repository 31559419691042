// src/Components/Research.js

import React, { useRef, useEffect } from 'react';
import './Research1.css';

// Import your research images
import ResScr1 from './L1 - Res - Scr1.png';
import ResScr2 from './L1 - Res - Scr2.png';
import ResScr3 from './L1 - Res - Scr3.png';
import ResScr4 from './L1 - Res - Scr4.png';
import ResScr5 from './L1 - Res - Scr5.png';
import ResScr6 from './L1 - Res - Scr6.png';

export default function Research() {
  const sectionRef = useRef(null);
  const imageRefs = useRef([]);
  const imagesContainerRef = useRef(null);

  useEffect(() => {
    const images = imageRefs.current;
    const totalImages = images.length;
    const section = sectionRef.current;
    const imagesContainer = imagesContainerRef.current;

    const handleScroll = () => {
      if (!section || images.length === 0) return;

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const windowHeight = window.innerHeight;

      const scrollPosition = scrollTop - sectionTop;

      // Check if the user is within the Research section
      if (scrollTop >= sectionTop && scrollTop < sectionTop + sectionHeight - windowHeight) {
        imagesContainer.style.display = 'flex';
      } else {
        imagesContainer.style.display = 'none';
      }

      // Adjust the background position to simulate a fixed background
      section.style.backgroundPositionY = `${-scrollPosition}px`;

      // Total scrollable distance in the section
      const totalScroll = sectionHeight - windowHeight;

      images.forEach((img, index) => {
        const imageScrollStart = (index * totalScroll) / totalImages;
        const imageScrollEnd = ((index + 1) * totalScroll) / totalImages;

        const progress =
          (scrollPosition - imageScrollStart) / (imageScrollEnd - imageScrollStart);

        if (progress >= 0 && progress <= 1) {
          const easedProgress = progress; // Apply easing if desired

          // Transformations
          const translateY = (1 - easedProgress) * 50; // From 50vh to 0vh
          const rotateX = -180 + 180 * easedProgress;
          const opacity = easedProgress;

          img.style.transform = `translateY(${translateY}vh) rotateX(${rotateX}deg)`;
          img.style.opacity = opacity;

          // Ensure previous images are visible
          for (let i = 0; i < index; i++) {
            images[i].style.transform = `translateY(0vh) rotateX(0deg)`;
            images[i].style.opacity = 1;
          }
        } else if (progress < 0) {
          // Before the image's animation starts
          img.style.transform = `translateY(50vh) rotateX(-180deg)`;
          img.style.opacity = 0;

          // Hide subsequent images
          for (let i = index + 1; i < images.length; i++) {
            images[i].style.transform = `translateY(50vh) rotateX(-180deg)`;
            images[i].style.opacity = 0;
          }
        } else if (progress > 1) {
          // After the image's animation ends
          img.style.transform = `translateY(0vh) rotateX(0deg)`;
          img.style.opacity = 1;

          // Ensure subsequent images are hidden
          for (let i = index + 1; i < images.length; i++) {
            images[i].style.transform = `translateY(50vh) rotateX(-180deg)`;
            images[i].style.opacity = 0;
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Initial call
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const images = [
    { src: ResScr1, alt: 'Research 1' },
    { src: ResScr2, alt: 'Research 2' },
    { src: ResScr3, alt: 'Research 3' },
    { src: ResScr4, alt: 'Research 4' },
    { src: ResScr5, alt: 'Research 5' },
    { src: ResScr6, alt: 'Research 6' },
  ];

  return (
    <div className="research1-section" ref={sectionRef}>
      <div className="images-container" ref={imagesContainerRef}>
        {images.map((image, index) => (
          <img
            key={index}
            ref={(el) => (imageRefs.current[index] = el)}
            src={image.src}
            alt={image.alt}
            className="research1-image"
            style={{
              opacity: 0,
              transform: 'translateY(50vh) rotateX(-180deg)',
            }}
          />
        ))}
      </div>
    </div>
  );
}
