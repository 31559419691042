import React from 'react';
import './VideoBackground.css';

const VideoBackground = ({ videoSrc, customClass }) => {
  return (
    <div className={`video-background-container ${customClass}`}>
      <video autoPlay loop muted className="video-background">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoBackground;

