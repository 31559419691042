import React, { useState, useEffect } from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './Components/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import all the page components
import Home from './Pages/Home';
import Ourapp from './Pages/Ourapp';
import Research from './Pages/Research';
import Indicators from './Pages/Indicators';
import Collaboration from './Pages/Collaboration';
import About from './Pages/About';
import Indicator1 from './Pages/Indicator1';
import Indicator2 from './Pages/Indicator2';
import Indicator3 from './Pages/Indicator3';
import Indicator4 from './Pages/Indicator4';
import Indicator5 from './Pages/Indicator5';
import Indicator6 from './Pages/Indicator6';

import SplashScreen from './Components/SplashScreen'; // Import the SplashScreen component
import HomeBackgroundVideo from './Components/Home Page 1 (Blank).mp4'; // Background video for Home

function App() {
  const [isSplashVisible, setIsSplashVisible] = useState(true); // Track splash screen state

  // Function to save scroll position on every scroll event
  const saveScrollPosition = () => {
    const scrollY = window.scrollY;
    localStorage.setItem('scrollPosition', scrollY); // Save scroll position to localStorage
  };

  useEffect(() => {
    // Check if the splash screen has been shown before
    const hasVisited = localStorage.getItem('hasVisited');
    
    if (hasVisited) {
      setIsSplashVisible(false); // Skip the splash screen if visited before
    } else {
      localStorage.setItem('hasVisited', 'true'); // Mark as visited
    }

    // Disable browser's automatic scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    // Restore scroll position after reload
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition !== null) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10)); // Restore saved scroll position
    }

    // Attach the scroll event listener to save the scroll position on scroll
    window.addEventListener('scroll', saveScrollPosition);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', saveScrollPosition);
    };
  }, []);

  // Once the splash screen ends, hide it
  const handleVideoEnd = () => {
    setIsSplashVisible(false); // Hide splash when the intro ends
  };

  return (
    <>
      {isSplashVisible && <SplashScreen onVideoEnd={handleVideoEnd} />} {/* Show splash screen */}

      {/* Preload Home background video */}
      <video
        src={HomeBackgroundVideo}
        style={{ display: 'none' }}
        onCanPlayThrough={handleVideoEnd} // Trigger once the video is loaded
      ></video>

      {!isSplashVisible && (
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/app" element={<Ourapp />} />
            <Route path="/indicators" element={<Indicators />} />
            <Route path="/research" element={<Research />} />
            <Route path="/contact" element={<Collaboration />} />
            <Route path="/about" element={<About />} />
            {/* Indicator Routes */}
            <Route path="/indicators/Bullish" element={<Indicator1 />} />
            <Route path="/indicators/Volume" element={<Indicator2 />} />
            <Route path="/indicators/Session" element={<Indicator3 />} />
            <Route path="/indicators/Metrics" element={<Indicator4 />} />
            <Route path="/indicators/EMAvol" element={<Indicator5 />} />
            <Route path="/indicators/AdaptiveEMA" element={<Indicator6 />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
