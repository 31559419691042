// src/Pages/Indicator3.js

import React from 'react';
import './Indicator.css';
import IndicatorImage from '../Components/L1-3 Ind3.png';
import Logo from '../Components/HL - Our App.png'; // Reuse the same logo

const Indicator3 = () => {
  return (
    <div className="indicator-page">
      {/* OPEN-SOURCE SCRIPT Label */}
      <div className="open-source-label">
        OPEN-SOURCE SCRIPT
      </div>

      {/* Indicator Title */}
      <h1 className="indicator-title">Forex Session Tracker</h1>

      {/* Publication Date */}
      <p className="publication-date">August 19, 2024</p>

      {/* Image Container with Clickable Image */}
      <div className="image-container">
        <a
          href="https://ru.tradingview.com/script/xi3HYqz8-Forex-Session-Tracker-MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="image-link"
        >
          <img
            src={IndicatorImage}
            alt="Forex Session Tracker Indicator"
            className="indicator-image"
          />
        </a>

        {/* TradingView Account Link */}
        <a
          href="https://ru.tradingview.com/u/MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="tradingview-account-link"
        >
          <img src={Logo} alt="MacroGlide Logo" className="logo-image" />
          <span className="logo-text">MacroGlide</span>
        </a>
      </div>

      {/* Description Section */}
      <div className="indicator-description">
        <h2>Description</h2>
        <p>
          Forex Session Tracker [MacroGlide] is a tool designed to track and visualize trading activity across the four key Forex market sessions: New York, London, Tokyo, and Sydney. The indicator helps traders see the time intervals of each session, their impact on price movements, and analyze volatility within these sessions.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Session Visualization: The indicator highlights price ranges during the New York, London, Tokyo, and Sydney sessions using different colors, making data easier to visually interpret and analyze. Users can customize the color scheme for each session.</li>
          <li>Price Change Analysis: The indicator tracks the opening prices of each session and calculates the price changes by the session's close. This allows traders to assess market dynamics within each session and make informed trading decisions.</li>
          <li>Average Price Changes: The average price change for a specified number of sessions is calculated for each session, helping to identify trends and volatility levels.</li>
          <li>Time Zone Support: The indicator takes into account time zones, allowing users to adjust the display according to their location or use the market's time zone.</li>
          <li>Interactive Dashboard: The built-in dashboard shows the status of each session in real-time (active or inactive), recent price changes, and average changes, providing quick access to key information directly on the chart.</li>
        </ul>

        <h2>How to Use</h2>
        <ul>
          <li>Add the indicator to your chart and configure the displayed sessions according to your needs.</li>
          <li>Use color differentiation to easily identify active trading sessions and assess their impact on price movements.</li>
          <li>Monitor price changes in each session and analyze averages for a deeper understanding of market trends.</li>
        </ul>

        <h2>Methodology</h2>
        <p>
          The indicator uses the time intervals of each trading session to calculate and display opening prices, price ranges, and price changes for the session. Based on this data, the Forex Session Tracker visualizes the session's high and low prices and calculates the average price change over the last several sessions. All data is displayed in real-time, considering the user's time zone settings or the market's time zone.
        </p>

        <h2>Originality and Usefulness</h2>
        <p>
          Forex Session Tracker [MacroGlide] stands out for its ability to combine price change information from several key trading sessions into one indicator, providing traders with a simple and clear way to analyze market activity across different time zones.
        </p>

        <h2>Charts</h2>
        <p>
          The indicator displays clean and clear charts, where each trading session is highlighted with its own color, making visual interpretation easier. The charts focus only on essential information for analysis: opening prices, session ranges, and price changes. The integrated dashboard provides quick access to key session metrics, such as activity status, recent price changes, and average values for the selected period. These features make the charts highly useful for rapid analysis and trading decision-making.
        </p>

        <h2>Enjoy the game!</h2>
      </div>

      {/* Bottom Link Button */}
      <a
        href="https://ru.tradingview.com/script/xi3HYqz8-Forex-Session-Tracker-MacroGlide/"
        className="indicator-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Use Indicator in TradingView
      </a>
    </div>
  );
};

export default Indicator3;
