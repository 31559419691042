// src/Pages/Indicator4.js

import React from 'react';
import './Indicator.css';
import IndicatorImage from '../Components/L1-3 - Ind4.png';
import Logo from '../Components/HL - Our App.png'; // Reuse the same logo

const Indicator4 = () => {
  return (
    <div className="indicator-page">
      {/* OPEN-SOURCE SCRIPT Label */}
      <div className="open-source-label">
        OPEN-SOURCE SCRIPT
      </div>

      {/* Indicator Title */}
      <h1 className="indicator-title">Forex Macro Metrics</h1>

      {/* Publication Date */}
      <p className="publication-date">September 6, 2024</p>

      {/* Image Container with Clickable Image */}
      <div className="image-container">
        <a
          href="https://ru.tradingview.com/script/sErWtusW-Forex-Macro-Metrics-MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="image-link"
        >
          <img
            src={IndicatorImage}
            alt="Forex Macro Metrics Indicator"
            className="indicator-image"
          />
        </a>

        {/* TradingView Account Link */}
        <a
          href="https://ru.tradingview.com/u/MacroGlide/"
          target="_blank"
          rel="noopener noreferrer"
          className="tradingview-account-link"
        >
          <img src={Logo} alt="MacroGlide Logo" className="logo-image" />
          <span className="logo-text">MacroGlide</span>
        </a>
      </div>

      {/* Description Section */}
      <div className="indicator-description">
        <h2>Description</h2>
        <p>
          "Forex Macro Metrics [MacroGlide]" is a powerful tool for analyzing macroeconomic metrics, designed to help traders make more informed decisions in the forex market. This indicator displays key economic indicators such as interest rates, money supply (M1 and M2), unemployment rate, and government debt for various currencies and their pairs, allowing users to assess the macroeconomic differences between the base and quote currencies.
        </p>

        <h2>Key Features</h2>
        <ul>
          <li>Interest Rates Display: Includes interest rates for major world currencies with the ability to show the differential between the base and quote currencies.</li>
          <li>Money Supply Analysis (M1 and M2): Displays the money supply for both the base and quote currencies, including differential calculations.</li>
          <li>Unemployment Rate: Compares the unemployment rates between currencies, showing the differences on the chart.</li>
          <li>Government Debt: Shows government debt levels for the base and quote currencies with differential calculations.</li>
          <li>Customizable Options: Enable/disable specific metrics and adjust colors for better visual clarity.</li>
        </ul>

        <h2>How to Use</h2>
        <ul>
          <li>Select a Currency Pair: Apply the indicator to your chart and choose the desired currency pair. The indicator will automatically load the relevant data for the base and quote currencies.</li>
          <li>Adjust Display Settings: Use the indicator settings to enable or disable specific metrics and their differentials.</li>
          <li>Analyze the Data: Compare the economic conditions of the two currencies through the charts and identify potential trading opportunities based on macroeconomic differences.</li>
        </ul>

        <h2>Methodology</h2>
        <p>
          The indicator uses economic data available through TradingView tickers to calculate the values of the base and quote currencies. Differentials are calculated by subtracting the values of the quote currency from the base currency, allowing for a visual assessment of their differences. The displayed data includes historical changes, helping to identify trends and potential reversal points.
        </p>

        <h2>Originality and Usefulness</h2>
        <p>
          "Forex Macro Metrics [MacroGlide]" is a unique tool that combines several key macroeconomic indicators into one comprehensive indicator. This simplifies the analysis process for traders looking to understand the fundamental differences between currencies. Using this approach provides an advantage in assessing long-term trends and potential shifts in currency pairs driven by changes in macroeconomic conditions.
        </p>

        <h2>Charts</h2>
        <p>
          The indicator displays data in the form of lines and areas on the chart, with interest rates shown as lines for the base and quote currencies, accompanied by an area representing the differential. For money supply (M1 and M2), lines are drawn for each currency, with areas highlighting the differences. Similarly, the unemployment rate and government debt are displayed with clear visual separation of the data and their differentials, making it easy to compare and analyze the macroeconomic conditions of the currencies involved.
        </p>

        <h2>Enjoy the game!</h2>
      </div>

      {/* Bottom Link Button */}
      <a
        href="https://ru.tradingview.com/script/sErWtusW-Forex-Macro-Metrics-MacroGlide/"
        className="indicator-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Use Indicator in TradingView
      </a>
    </div>
  );
};

export default Indicator4;
