import React, { useState, useRef } from 'react';
import x1 from '../Components/x1.png'; 
import FileIcon from '../Components/FileIcon.png'; // Import the file icon
import './Collaboration.css';

function Collaboration() {
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('Share an Idea');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]); // State for multiple attachments
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for custom dropdown

  const fileInputRef = useRef(null); // Ref to trigger file input

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    let newAttachments = [...attachments];
    let totalSize = newAttachments.reduce((acc, curr) => acc + curr.size, 0);

    for (let file of files) {
      if (newAttachments.length >= 5) {
        alert('You can only upload up to 5 files.');
        break;
      }
      if (totalSize + file.size > 5 * 1024 * 1024) {
        alert('Total attachment size cannot exceed 5 MB.');
        break;
      }
      newAttachments.push(file);
      totalSize += file.size;
    }
    setAttachments(newAttachments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData object to send files
    const formData = new FormData();
    formData.append('email', email);
    formData.append('topic', topic);
    formData.append('subject', subject);
    formData.append('message', message);
    if (attachments.length > 0) {
      attachments.forEach((file, index) => {
        formData.append(`attachment_${index}`, file);
      });
    }

    try {
      const response = await fetch('/send-email.php', { // Adjust the path as needed
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Form has been submitted successfully!');
        // Reset form fields
        setEmail('');
        setTopic('Share an Idea');
        setSubject('');
        setMessage('');
        setAttachments([]);
      } else {
        alert('There was an error submitting the form.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting the form.');
    }
  };

  // Function to truncate file name
  const getTruncatedFileName = (name) => {
    return name.length > 10 ? `${name.substring(0, 10)}...` : name;
  };

  const removeAttachment = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <h2 className="contact-title">SEND US A MESSAGE</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          {/* Email Field */}
          <label className="email-label">Email</label>
          <input
            className="email-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          {/* Topic Selector */}
          <label className="topic-label">Topic</label>
          <div
            className="topic-select"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {topic}
          </div>
          {isDropdownOpen && (
            <div className="topic-options">
              {['Share an Idea', 'Report an Issue', 'Collaborate with Us', 'Other'].map((option) => (
                <div
                  key={option}
                  className="topic-option"
                  onClick={() => {
                    setTopic(option);
                    setIsDropdownOpen(false);
                  }}
                >
                  {option}
                </div>
              ))}
            </div>
          )}

          {/* Subject Field */}
          <label className="subject-label">Subject</label>
          <input
            className="subject-input"
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />

          {/* Message Field */}
          <label className="message-label">Message</label>
          <textarea
            className="message-textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>

          {/* Attachments */}
          <div className="attachment-container">
            <div
              className="add-attachment"
              onClick={() => fileInputRef.current.click()} // Trigger file input
            >
              <img src={x1} alt="Add Attachment" className="paperclip-icon" />
              <span>Add Attachment</span>
            </div>
            {/* Hidden File Input */}
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
            />
            {/* Display list of attached files */}
            {attachments.length > 0 && (
              <div className="attached-files">
                {attachments.map((file, index) => (
                  <div key={index} className="file-item">
                    <img src={FileIcon} alt="File Icon" className="file-icon" />
                    <span className="file-name">
                      {getTruncatedFileName(file.name)}
                    </span>
                    <span
                      className="remove-icon"
                      onClick={() => removeAttachment(index)}
                    > 
                      &times;
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Submit Button */}
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Collaboration;