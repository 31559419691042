// src/Pages/Home.js

import React, { useRef, useEffect, useState } from "react";
import VideoBackground from "../Components/VideoBackground";
import HomeVideo from "../Components/L1 - Plnt (v6).mp4";
import AnimationVideo from "../Components/Animation.mp4";
import AppBackground from "../Components/App-Background.png";
import './Home.css';
import anime from 'animejs/lib/anime.es.js';
import CarouselIndicators from '../Components/CarouselIndicators';
import Research from '../Components/Research1';
import ContactUs from '../Pages/ContactUs';
import Ft from '../Pages/Ft';
import { Link } from 'react-router-dom'; // Import Link for navigation

export default function Home() {
    const animationRef = useRef(null);
    const animationVideoRef = useRef(null);
    const appSectionRef = useRef(null);
    const aboutSectionRef = useRef(null);
    const indicatorsSectionRef = useRef(null); // Ref for Indicators section
    const [isAnimationVisible, setIsAnimationVisible] = useState(false);
    const [showAppContent, setShowAppContent] = useState(false);
    const [hasAnimationPlayed, setHasAnimationPlayed] = useState(false);
    const [hasAboutAnimationPlayed, setHasAboutAnimationPlayed] = useState(false);

    // Helper function to wrap each letter in a span
    const wrapTextWithSpans = (text) => {
        return text.split('').map((char, index) => {
            const className = char === ' ' ? 'letter space' : 'letter';
            return (
                <span key={index} className={className}>{char}</span>
            );
        });
    };

    // Initial Animations for the Main Content
    useEffect(() => {
        anime.timeline({ loop: false })
            .add({
                targets: '.main-text .letter',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 1000,
                delay: (el, i) => 50 * (i + 1)
            })
            .add({
                targets: '.transforming-text',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 1000,
            })
            .add({
                targets: '.explore-button',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 1000,
                delay: 200
            });
    }, []);

    // Intersection Observer for the "About" Section
    useEffect(() => {
        let observer;
        const aboutSectionElement = aboutSectionRef.current; // Store the current ref in a variable

        if (aboutSectionElement) {
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting && !hasAboutAnimationPlayed) {
                            console.log("About section is in view. Starting animations.");
                            anime.timeline({ loop: false })
                                .add({
                                    targets: '.new-main-text .letter',
                                    opacity: [0, 1],
                                    easing: "easeInOutQuad",
                                    duration: 1000,
                                    delay: (el, i) => 50 * (i + 1)
                                })
                                .add({
                                    targets: '.mission-text',
                                    opacity: [0, 1],
                                    easing: "easeInOutQuad",
                                    duration: 1000,
                                })
                                .add({
                                    targets: '.separator-line',
                                    width: ['0%', '100%'],
                                    opacity: [0, 1],
                                    easing: "easeInOutQuad",
                                    duration: 1000,
                                    delay: 200
                                })
                                .add({
                                    targets: '.paragraph',
                                    opacity: [0, 1],
                                    translateX: ['-50px', '0px'],
                                    easing: "easeOutExpo",
                                    duration: 1000,
                                    delay: (el, i) => 200 * i
                                });
                            setHasAboutAnimationPlayed(true);
                            observer.unobserve(aboutSectionElement);
                        }
                    });
                },
                { 
                    threshold: 0.3,
                    rootMargin: "0px 0px -25% 0px"
                }
            );
            observer.observe(aboutSectionElement);
        }

        return () => {
            if (observer && aboutSectionElement) {
                observer.unobserve(aboutSectionElement);
            }
        };
    }, [hasAboutAnimationPlayed]);    

    // Intersection Observer for the animation video
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !hasAnimationPlayed) {
                        console.log("App section is in view. Showing animation video.");
                        setIsAnimationVisible(true);
                    }
                });
            },
            { threshold: 0.4 }
        );

        const currentAppSectionRef = appSectionRef.current;
        if (currentAppSectionRef) {
            observer.observe(currentAppSectionRef);
        }

        return () => {
            if (currentAppSectionRef) {
                observer.unobserve(currentAppSectionRef);
            }
        };
    }, [hasAnimationPlayed]);   
    
    // Intersection Observer for the Background Image Mask Animation
    useEffect(() => {
        const sectionRef = indicatorsSectionRef.current; // Store the section ref in a variable

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && entry.intersectionRatio >= 0.4) {
                        console.log("Triggering mask animation for background image.");
                        const maskElement = entry.target.querySelector('.indicators-bg'); // Target the mask element
                        if (maskElement) {
                            maskElement.classList.add('reveal'); // Add the reveal class to start animation
                        }
                        observer.unobserve(entry.target); // Stop observing after triggering
                    }
                });
            },
            { threshold: 0.4 } // Trigger when 40% of the section is visible
        );

        if (sectionRef) {
            observer.observe(sectionRef);
        }

        // Cleanup function
        return () => {
            if (sectionRef) {
                observer.unobserve(sectionRef);
            }
        };
    }, []);

    // Handle Animation Video Playback and Trigger "App Content" Visibility
    useEffect(() => {
        if (isAnimationVisible && animationVideoRef.current) {
            const video = animationVideoRef.current;
            console.log("Starting video playback...");

            // Initially hide the app content when the video starts
            setShowAppContent(false);

            video.play().then(() => {
                console.log("Video is playing.");
            }).catch((error) => {
                console.error("Error playing video:", error);
            });

            // Show the app content after the video ends
            video.onended = () => {
                console.log("Video has ended.");
                setIsAnimationVisible(false);
                setShowAppContent(true);  // Show app content now
                setHasAnimationPlayed(true);
            };
        }

        // Ensure app content animations trigger after the video ends
        if (showAppContent) {
            anime.timeline({ loop: false })
                .add({
                    targets: '.app-content .app-logo',
                    opacity: [0, 1],
                    translateY: [-50, 0],
                    easing: "easeOutExpo",
                    duration: 1000
                })
                .add({
                    targets: '.app-content .app-heading',
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    duration: 1000,
                    delay: 200
                })
                .add({
                    targets: '.app-content .app-subheading',
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    duration: 1000,
                    delay: 200
                })
                .add({
                    targets: '.app-content .app-button',
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    duration: 1000,
                    delay: 200
                });
        }
    }, [isAnimationVisible, showAppContent]);

    // Smooth scrolling effect for the "Explore Horizons" button
    useEffect(() => {
        const anchors = document.querySelectorAll('a[href^="#"]');
        const handleClick = function (e) {
            e.preventDefault();
            const target = document.querySelector(this.getAttribute('href'));
            if (target) {
                target.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        };

        anchors.forEach(anchor => {
            anchor.addEventListener('click', handleClick);
        });

        return () => {
            anchors.forEach(anchor => {
                anchor.removeEventListener('click', handleClick);
            });
        };
    }, []);

    // Intersection Observer for Indicators Section
    useEffect(() => {
        const indicatorsSection = indicatorsSectionRef.current;
    
        if (indicatorsSection) {
        const observer = new IntersectionObserver(
            (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                console.log("Indicators section is visible.");
    
                // Trigger mask animation
                const mask = entry.target.querySelector('.indicators-mask');
                if (mask) {
                    mask.style.animationPlayState = 'running'; // Start the mask animation
                }
    
                observer.unobserve(entry.target); // Stop observing after triggering
                }
            });
            },
            { threshold: 0.4 } // Trigger when 40% of the section is visible
        );
    
        observer.observe(indicatorsSection);
    
        return () => observer.disconnect();
        }
    }, []);  



    return (
        <>
            {/* Video Section */}
            <div className="video-section">
                <VideoBackground videoSrc={HomeVideo} customClass="home-background" />
                <div className="home-content top-content">
                    <h1 className="main-text">
                        <span className="line">{wrapTextWithSpans("YOUR EASY WAY")}</span>
                        <span className="line">{wrapTextWithSpans("TO FOLLOW MARKET TRENDS")}</span>
                    </h1>
                    <p className="sub-text transforming-text">
                        <span className="line">
                            <span className="gradient-transforming">{wrapTextWithSpans("TRANSFORMING")}</span>
                            <span className="white-text">{wrapTextWithSpans(" THE ")}</span>
                            <span className="gradient-chaos">{wrapTextWithSpans("CHAOS")}</span>
                            <span className="white-text">{wrapTextWithSpans(" OF THE MARKETS")}</span>
                        </span>
                        <span className="line">
                            <span className="white-text">{wrapTextWithSpans("INTO ")}</span>
                            <span className="gradient-opportunities">{wrapTextWithSpans("OPPORTUNITIES")}</span>
                        </span>
                    </p>

                    <a href="#about" className="explore-button">Explore Horizons</a>
                </div>

                {/* About Section */}
                <div id="about" className="home-content bottom-content" ref={aboutSectionRef}>
                    <h1 className="new-main-text">
                        <span className="line">{wrapTextWithSpans("At MacroGlide")}</span>
                    </h1>
                    <p className="new-sub-text mission-text">
                        <span className="line">
                            <span className="white-text">{wrapTextWithSpans("Our ")}</span>
                            <span className="gradient-mission">{wrapTextWithSpans("mission")}</span>
                            <span className="white-text">{wrapTextWithSpans(" is making ")}</span>
                            <span className="gradient-successful-investing">{wrapTextWithSpans("successful investing")}</span>
                            <span className="white-text">{wrapTextWithSpans(" and ")}</span>
                            <span className="gradient-trading-accessible">{wrapTextWithSpans("trading accessible")}</span>
                            <span className="white-text">{wrapTextWithSpans(" to ")}</span>
                            <span className="gradient-everyone">{wrapTextWithSpans("everyone")}</span>
                        </span>
                    </p>

                    <hr className="separator-line" />
                    {/* Paragraphs Container */}
                    <div className="paragraphs-container">
                        <p className="paragraph">
                            By combining cutting-edge technology with deep analytical insights, we provide products that simplify complex tasks, allowing you to focus on what matters most - achieving your financial objectives. Experience market dynamics with MacroGlide is the best way to realize the full potential of life!
                        </p>
                        <p className="paragraph">
                            In an industry filled with complexity and uncertainty, MacroGlide is committed to clarity and innovation. Our tools and reliable data empower traders and investors to make informed decisions and stay ahead of the market. Regardless of your experience level or goals, our solutions help you confidently navigate the financial markets.
                        </p>
                    </div>
                </div>
            </div>

            {/* App Section */}
            <div id="app" className="app-section" ref={appSectionRef} style={{ backgroundImage: `url(${AppBackground})` }}>
                {/* Animation Video Overlay */}
                {isAnimationVisible && (
                    <div className="animation-video-container visible" ref={animationRef}>
                        <video
                            ref={animationVideoRef}
                            muted
                            className="animation-video"
                        >
                            <source src={AnimationVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                {/* App Content */}
                {showAppContent && (
                    <div className={`app-content ${showAppContent ? 'visible' : ''}`}>
                        <h2 className="app-heading">MACROGLIDE APP</h2>
                        <h3 className="app-subheading">Easy Way to Follow Market Trends</h3>
                        <div className="app-buttons">
                            <Link to="/app" className="app-button">Explore Our App</Link>
                            <Link to="/download" className="app-button">Download App</Link> {/* Changed to Link for consistency */}
                        </div>
                    </div>
                )}
            </div>

            {/* Indicators Section */}
            <div id="indicators" className="indicators-section" ref={indicatorsSectionRef}>
            {/* Static Background Image */}
            <div className="indicators-bg"></div>

            {/* Mask that will slide to reveal the image */}
            <div className="indicators-mask"></div>

            {/* Title and Buttons */}
            <h1 className="indicators-title">TRADINGVIEW INDICATORS</h1>
            <div className="indicators-buttons">
                <Link to="/indicators" className="indicators-button">Explore More</Link>
                <Link to="/collaboration" className="indicators-button get-access-button">Get Access</Link>
            </div>
            </div>
            {/* Carousel Indicators Section */}
            <div id="carousel-indicators" className="carousel-indicators-section">
                <CarouselIndicators />
            </div>
            
            {/* Research Section */}
            <Research />
            {/* ContactUs Section */}
            <ContactUs />
            {/* Ft Section */}
            <Ft />
        </>
    );
}
