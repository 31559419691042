import React, { useState, useEffect, useRef } from 'react';
import './Research.css';

// Importing research images
import Res1 from '../Components/L1-3 - Res1.png';
import Res2 from '../Components/L1-3 - Res2.png';
import Res3 from '../Components/L1-3 - Res3.png';
import Res4 from '../Components/L1-3 - Res4.png';
import Res5 from '../Components/L1-3 - Res5.png';
import Res6 from '../Components/L1-3 - Res6.png';

// Importing the background image
import Background from '../Components/L2 - Res - Bkg.png';

const researchData = [
  {
    image: Res1,
    title: 'Price Movements and Volume: Assessing the Consistency of Trading Volumes with Price Movements',
    date: 'June 2024',
    abstract: `This study analyzes the frequency at which trading volumes confirm or do not confirm price movements in the stock market. Confirmation is defined as the alignment of price direction with predominant volume: if the price rises with more bullish volumes or falls with more bearish volumes. Non-confirmation occurs in opposite scenarios. The study covers 104 financial instruments, including 4 major US indices and 100 stocks with the largest market capitalizations from the S&P 500, from January 1, 2020, to January 1, 2024, encompassing 1008 trading days. Data collected from polygon.io includes minute-by-minute opening prices and trading volumes. Typically, exchanges provide only total volumes, so we classified volumes as bullish or bearish based on minute-by-minute price changes: an increase in price classifies the volume as bullish, while a decrease classifies it as bearish. The data were aggregated into daily, four-hour, and hourly intervals and compared with price changes to determine the frequency of volume confirmation or non-confirmation of price movements. Calculations were performed using Python, allowing efficient analysis of over 81 million data points.`,
    link: 'https://www.researchgate.net/publication/381478867_Price_Movements_and_Volume_Assessing_the_Consistency_of_Trading_Volumes_with_Price_Movements',
  },
  {
    image: Res2,
    title: 'Bullish/Bearish Volume: Development and Application of a TradingView Indicator',
    date: 'June 2024',
    abstract: `This overview article presents the development and application of the "Bullish/Bearish Volume" indicator, designed to provide traders with a detailed analysis of trading volumes based on minute-by-minute price data. The motivation for creating this indicator stemmed from a comprehensive study that examined the frequency at which trading volumes confirm or do not confirm price movements. The study revealed that trading volumes often do not align with price movements, highlighting the need for a tool that can accurately differentiate between bullish (buying) and bearish (selling) volumes. The "Bullish/Bearish Volume" indicator addresses this need by classifying trading volumes based on closing price data, offering a granular view of market sentiment. It aggregates data from a fixed 1-minute timeframe to ensure consistency and accuracy, making it a valuable tool for identifying periods of active buying and selling pressure. This article outlines the methodology, technical implementation, and practical applications of the indicator, demonstrating its effectiveness in enhancing traders' decision-making processes. Key features of the indicator include the separation of volumes into bullish and bearish categories, the calculation of total volume and volume delta, and the visualization of volume mismatches and trends. By providing a clearer understanding of market dynamics, the "Bullish/Bearish Volume" indicator empowers traders to make more informed trading decisions and better manage market risks.`,
    link: 'https://www.researchgate.net/publication/381733730_BullishBearish_Volume_Development_and_Application_of_a_TradingView_Indicator',
  },
  {
    image: Res3,
    title: 'Volume Profile: Development and Application of a TradingView Indicator',
    date: 'July 2024',
    abstract: `This overview article presents the development and application of the "Volume Profile" indicator, designed to offer traders a comprehensive analysis of trading volumes and their distribution across different price levels. The motivation behind creating this indicator was driven by the need for a tool that could provide detailed insights into the volume concentration at various price points, which is crucial for identifying key support and resistance levels. The "Volume Profile" indicator addresses this need by segmenting the trading volume into bullish and bearish categories based on price movement and displaying it in a visually intuitive manner. By analyzing the distribution of volume over a specified number of bars, this indicator helps traders understand the areas where significant trading activity has occurred, highlighting potential price levels where the market might react. It incorporates several customizable features, including the calculation of value area volume, point of control (POC) lines, and the distinction between up and down volume, making it a versatile tool for various trading strategies. This article outlines the methodology, technical implementation, and practical applications of the indicator, demonstrating its effectiveness in enhancing traders' decision-making processes. Key features include the segmentation of volume into different categories, the visualization of value areas, and the ability to adjust parameters for personalized analysis. By providing a clearer understanding of market dynamics and volume distribution, the "Volume Profile" indicator empowers traders to make more informed trading decisions and better manage market risks.`,
    link: 'https://www.researchgate.net/publication/382133097_Volume_Profile_Development_and_Application_of_a_TradingView_Indicator',
  },
  {
    image: Res4,
    title: 'Forex Session Tracker: Development and Application of a TradingView Indicator',
    date: 'August 2024',
    abstract: `This overview article delves into the development and practical application of the "Forex Session Tracker [MacroGlide]" indicator, a sophisticated tool designed to offer traders an in-depth understanding of market activity during the major Forex trading sessions. The primary motivation for creating this indicator stemmed from the need for a robust tool that could meticulously monitor, track, and visualize price movements across key global trading sessions, namely New York, London, Tokyo, and Sydney. Given the significance of these sessions in influencing market volatility and trends, the "Forex Session Tracker" serves as an essential instrument for traders aiming to identify and capitalize on session-specific market behaviors. The "Forex Session Tracker" is particularly useful in providing a detailed analysis of session-specific price changes, which is crucial for identifying potential entry and exit points. It allows traders to monitor the start prices of each session, track price changes throughout the session, and visualize the range of price movements within each session's timeframe. Additionally, the indicator calculates the average price change over a user-defined number of sessions, offering insights into the typical market behavior during these periods. This article provides a comprehensive overview of the methodology behind the indicator, its technical implementation, and the practical applications that make it a valuable tool in a trader’s arsenal. By integrating customizable features such as session-specific colors, start price tracking, and the ability to display a dashboard summarizing session data, the indicator ensures a versatile and user-friendly experience.`,
    link: 'https://www.researchgate.net/publication/383343954_Forex_Session_Tracker_Development_and_Application_of_a_TradingView_Indicator',
  },
  {
    image: Res5,
    title: 'Forex Macro Metrics: Development and Application of a TradingView Indicator',
    date: 'September 2024',
    abstract: `This article introduces the "Forex Macro Metrics" (FMM) indicator, a tool designed to assist forex traders by providing a comprehensive analysis of essential macroeconomic indicators. The development of this indicator was driven by the need for a straightforward way to compare key economic metrics—such as interest rates, money supply (M1 and M2), unemployment rates, and government debt—across different currency pairs. Understanding these macroeconomic factors is crucial for anticipating long-term currency trends, yet many traders lack a clear way to visualize their impact in real time. The "Forex Macro Metrics" indicator fills this gap by allowing users to observe and compare the economic strength of the base and quote currencies through customizable visualizations of the relevant metrics. This article covers the rationale behind the indicator, the technical details of its construction, and how it can be used to enhance trading decisions. Core functions include displaying the differential between key economic indicators for paired currencies, allowing traders to easily assess the macroeconomic backdrop influencing price movements. The indicator's ability to present these complex relationships in an intuitive format empowers traders to approach the forex market with greater confidence and strategic insight.`,
    link: 'https://www.researchgate.net/publication/384113326_Forex_Macro_Metrics_Development_and_Application_of_a_TradingView_Indicator',
  },
  {
    image: Res6,
    title: 'EMA Volume: Development and Application of a TradingView Indicator',
    date: 'September 2024',
    abstract: `This article provides an overview and description of the "EMA Volume [MacroGlide]" indicator, developed to offer traders a comprehensive analysis of trading volumes through the use of Exponential Moving Averages (EMA). The primary motivation behind the creation of this indicator was to design a tool that could detail the movement of volumes based on price direction, which is crucial for identifying prevailing market trends. The "EMA Volume [MacroGlide]" indicator addresses this need by segmenting volume into bullish and bearish categories based on price changes, providing a clear and intuitive visualization of market activity. Through the calculation of EMA for total, bullish, and bearish volumes, this indicator enhances the trader’s ability to understand the underlying dynamics of market movements. This article outlines the methodology, technical implementation, and practical applications of the indicator, showcasing its effectiveness in improving trading decisions and market risk management. Key features include the ability to customize EMA parameters, the clear distinction between bullish and bearish volume, and flexible visualization options. By offering a deeper insight into market dynamics and volume trends, the "EMA Volume [MacroGlide]" empowers traders to make more informed decisions and better navigate market conditions.`,
    link: 'https://www.researchgate.net/publication/384153525_EMA_Volume_Development_and_Application_of_a_TradingView_Indicator',
  },
];

const Research = () => {
  // State to track expanded research items
  const [expandedItems, setExpandedItems] = useState([]);
  const sectionsRef = useRef([]);

  // Function to toggle expanded state
  const toggleExpand = (index) => {
    setExpandedItems((prev) => {
      const newExpanded = [...prev];
      if (newExpanded.includes(index)) {
        // If already expanded, collapse it
        return newExpanded.filter((item) => item !== index);
      } else {
        // If not expanded, expand it
        return [...newExpanded, index];
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <div className="research-container">
      <h1 className="research-header">RESEARCH & ARTICLES</h1>
      <div className="research-sections">
        {researchData.map((research, index) => (
          <div
            key={index}
            className="research-section"
            ref={(el) => (sectionsRef.current[index] = el)}
          >
            <div className="research-image">
              <img src={research.image} alt={`Research ${index + 1}`} loading="lazy" />
            </div>
            <div className="research-content">
              <h2 className="research-title">{research.title}</h2>
              <p className="research-date">{research.date}</p>
              <p
                className={`research-abstract ${
                  expandedItems.includes(index) ? 'expanded' : ''
                }`}
              >
                {research.abstract}
              </p>
              {/* Conditionally render the "Full Research" link when expanded */}
              {expandedItems.includes(index) && (
                <a
                  href={research.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="full-research-link"
                >
                  Full Research
                </a>
              )}
              <button
                className="continue-button"
                onClick={() => toggleExpand(index)}
                aria-label={
                  expandedItems.includes(index)
                    ? `Collapse ${research.title}`
                    : `Continue reading about ${research.title}`
                }
              >
                {expandedItems.includes(index) ? 'Show Less' : 'Continue Reading'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Research;
