// src/Components/Ft.js

import React from 'react';
import './Ft.css';
import BackgroundImage from '../Components/L1 - Ft (v15).png';

export default function Ft() {
  return (
    <section className="ft-section">
      <img src={BackgroundImage} alt="Ft Background" className="background-image" />
    </section>
  );
}
