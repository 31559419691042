// Header.js
import React, { Component } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './Logo.png';
import './Header.css';

export default class Header extends Component {
    render() {
        return (
            <>
                <Navbar fixed="top" collapseOnSelect expand="md" className="transparent-blur-navbar">
                    <Container fluid>
                        <Navbar.Brand as={Link} to="/" className="brand-name">
                            <img
                                src={logo}
                                height="40" // Set the logo's height here
                                width="40"  // Set the logo's width here
                                className="d-inline-block align-top"
                                alt="Logo"
                            />
                            MacroGlide
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto nav-links-right">
                                <Nav.Link as={Link} to="/app">Our App</Nav.Link>
                                <Nav.Link as={Link} to="/indicators">Indicators</Nav.Link>
                                <Nav.Link as={Link} to="/research">Research</Nav.Link>
                                <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
                                <Nav.Link as={Link} to="/about" className="about-us-btn">About Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}
