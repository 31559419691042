import React from 'react';
import './ContactUs.css';
import BackgroundVideo from '../Components/L1 - Anm3.mp4';
import { Link } from 'react-router-dom';

export default function ContactUs() {
  return (
    <section className="contact-us-section">
      <div className="video-background2">
        <video
          src={BackgroundVideo}
          type="video/mp4"
          autoPlay
          muted
          loop
          className="background-video2"
        />
      </div>
      <div className="overlay"></div>
      <div className="contact-content">
        <h2 className="contact-heading">CONTACT US</h2>
        <h3 className="contact-subheading">
          We are always excited to hear from our community!
        </h3>
        <div className="paragraphs2-container">
          <p className="paragraph2">
            If you have an idea for a project we could collaborate on, a suggestion for a new
            product you would like to see in our lineup, or if you have discovered a bug that needs
            fixing, please feel free to share it with us. Your ideas and feedback play a crucial
            role in helping us evolve and improve the experience we offer. They guide us in better
            understanding your needs.
          </p>
          <p className="paragraph2">
            At the heart of what we do is a commitment to meeting the needs of our users. That is why your input is so important to us. Whether it is a big idea or a small tweak, every
            message is valuable, and we make sure to take it seriously. Together, we can shape a
            better future and deliver products and services that truly make a difference. Reach out
            – we are ready to listen!
          </p>
        </div>
        <div className="button-container">
          <Link to="/collaboration" className="contact-button">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
}
